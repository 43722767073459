@import "./settings/_colors.pcss";

@import "./generic/_base.pcss";
@import "./generic/_reset.pcss";

@import "./elements/_forms.pcss";
@import "./elements/_headings.pcss";
@import "./elements/_hr.pcss";
@import "./elements/_layout.pcss";
@import "./elements/_links.pcss";
@import "./elements/_sections.pcss";
@import "./elements/_tables.pcss";

@import "./objects/_animations.pcss";
@import "./objects/_buttons.pcss";
@import "./objects/_charges_grid.pcss";
@import "./objects/_checkout_modal.pcss";
@import "./objects/_clickable.pcss";
@import "./objects/_dropdowns.pcss";
@import "./objects/_hint-label.pcss";
@import "./objects/_icons.pcss";
@import "./objects/_lists.pcss";
@import "./objects/_loading-bar.pcss";
@import "./objects/_logo.pcss";
@import "./objects/_panels.pcss";
@import "./objects/_practice_pulse.pcss";
@import "./objects/_tags.pcss";
@import "./objects/_tiles.pcss";
@import "./objects/_typography.pcss";
@import "./objects/_widgets.pcss";

@import "./components/_debug-info.pcss";
@import "./components/_footer.pcss";
@import "./components/_modals.pcss";
@import "./components/_navigation.pcss";
@import "./components/_print.pcss";
@import "./components/_signin.pcss";
@import "./components/_stripe-elements.pcss";
@import "./components/_ui-select.pcss";
@import "./components/_search.pcss";

@import "./hacks/_shame.pcss";
@import "./hacks/_notification-email-layout.pcss";

#ng-app {
  height: 100%;
}

.header,
.hero,
.redesign-hide,
#header-admin {
  display: none;
}
